import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserRouter } from 'react-router-dom';
// import { PostHogProvider} from 'posthog-js/react'
import { HelmetProvider } from 'react-helmet-async';

import App from './app';

// --------------------------------- POSTHOG START -----------------------------------

// const options = {
//   api_host: process.env.VITE_POSTHOG_HOST,
// }

// ---------------------------------- POSTHOG END -------------------------------------

// --------------------------------- SENTRY START -------------------------------------

const authInfo = JSON.parse(localStorage.getItem('auth_info'));

let user = {
  id: 0,
  email: "anonymous@email.com"
};

if (authInfo && authInfo.user && authInfo.user.email && authInfo.user.mobile) {
  user = {
    id: authInfo.user.mobile,
    email: authInfo.user.email
  };
}

if (`${import.meta.env.VITE_SENTRY_STATUS}` === 'ACTIVE') {
  Sentry.init({
    dsn: "https://22aed7dd96db1ec347e5ff06f5d10d2d@o4506830316896256.ingest.sentry.io/4506830321614848",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/apiv2.seedsoft\.in\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Add custom user data
  Sentry.setUser(user);

  // Add auth info to context if it exists
  if (authInfo) {
    Sentry.setContext('auth_info', authInfo);
  }

}

// --------------------------------- SENTRY END -------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
      {/* {
        `${process.env.VITE_POSTHOG_STATUS}` === 'ACTIVE' ? (
          <PostHogProvider
            apiKey={process.env.VITE_POSTHOG_KEY}
            options={options}
          >
            <App />
          </PostHogProvider>
        ) : (
          <App />
        )
      } */}

        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
