import { useState,useEffect} from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

// ----------------------------------------------------------------------

export default function FinancialYearPopover() {
  // eslint-disable-next-line radix
  const [year, setYear] = useState(parseInt(localStorage.getItem('year')) || new Date().getFullYear());

  useEffect(() => {
    localStorage.setItem('year', year);
  }, [year]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select value={year} label="Financial Year" onChange={handleYearChange}>
        <MenuItem value={2024}>2024-25</MenuItem>
        <MenuItem value={2023}>2023-24</MenuItem>
        <MenuItem value={2022}>2022-23</MenuItem>
        <MenuItem value={2021}>2021-22</MenuItem>
      </Select>
    </FormControl>
  );
}
